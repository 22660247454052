





import Vue from 'vue'
import { Component } from 'vue-property-decorator'
@Component
export default class OtherLogin extends Vue {
  created(): void {
    console.log('OtherLoginOtherLogin')
    let type = this.$route.query.type
    let code = this.$route.query.code || ''
    let state = this.$route.query.state || ''
    let url = ''
    if (type === 'wb') {
      url = this.$api.login_wb
    } else if (type === 'wx') {
      url = this.$api.login_wx
    } else if (type === 'qq') {
      url = this.$api.login_qq
    } else {
      url = ''
    }
    // 回调成功
    if (code && state && url) {
      this.login(code, state, type, url)
    } else {
      this.$router.replace({ name: 'login' })
    }
  }
  login(
    code: string | (string | null)[],
    state: string | (string | null)[],
    type: string | (string | null)[],
    url: string
  ): void {
    let row = {
      code: code,
      state: state
    }
    this.$http
      .post(url, row)
      .then((res: any) => {
        console.log('res', res)
        const msg = res.data.meta.message
        if (res.data.meta.status_code === 200) {
          console.log('设置cookie并跳转页面')
          const token = res.data.data.ticket
          // 设置cookie并跳转页面
          this.$store.commit('setToken', token)
          this.$message.success(msg)
        } else if (res.data.meta.status_code === 201) {
          let name = res.data.data.name || ''
          let union_id = res.data.data.unionid || ''
          let open_id = res.data.data.openid || ''
          this.$router.push({
            name: 'bindPhone',
            query: {
              type: type,
              state: state,
              name: name,
              union_id: union_id,
              open_id: open_id
            }
          })
        } else {
          this.$message.error(msg)
          this.$router.push({ name: 'login' })
        }
      })
      .catch((error: any) => {
        console.log('sssasd', error)
        console.log('err', error)
      })
  }
}
